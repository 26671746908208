<template>
  <div>
    <h1>Test Page</h1>
    <router-link to="/home">Home</router-link>
  </div>
</template>

<script>

import { dashboardPageCommonAttributes } from '@/mixins/DashboardPageCommonAttributes'

export default {
  ...dashboardPageCommonAttributes,
  components: {
  },
  head: {
    title: function () {
      return {
        inner: 'Test page'
      }
    }
  }
}
</script>
